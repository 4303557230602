import React, { useState } from "react";
import {
  Editor,
  EditorState,
  RichUtils,
  convertFromHTML,
  ContentState,
} from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import ClipboardButtonsBar from "../../../../Dashboard/kampaign/ClipboardButtonsBar";
import { PencilIcon, Bold, Italic, Underline } from "lucide-react";
import "draft-js/dist/Draft.css";

const customStyleMap = {
  SVG_UNTERSTRICH: {
    backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 400 20' preserveAspectRatio='none'><path d='M0,15 C50,25 150,5 200,15 C250,25 350,5 400,15' stroke='red' stroke-width='6' fill='none' stroke-linecap='round' /></svg>")`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "0 100%",
    backgroundSize: "100% 0.8em",
    paddingBottom: "0.2em",
  },
};

/**
 * @param {string} text        - Initialer HTML-String
 * @param {function} onSave    - Callback, der den neuen HTML-String zurückgibt
 * @param {string} element     - Welches HTML-Element (z. B. 'h1', 'p', 'div') gerendert werden soll
 * @param {string} className   - Zusätzliche Klassen für das Wrapper-Element
 * @param {number} maxLines    - Maximal erlaubte Zeilenanzahl
 */
const EditableText = ({
  text,
  onSave,
  element: Element = "div",
  className = "",
  maxLines = 2,
  maxChars = 100,
}) => {
  // HTML -> Draft-EditorState
  const blocksFromHTML = convertFromHTML(text || "");
  const initialContent = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );

  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(initialContent)
  );

  const [isEditing, setIsEditing] = useState(false);

  // Toggle Inline Styles (Bold, Italic, Underline)
  const toggleInlineStyle = (style) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, style));
  };

  // Wenn Benutzer tippt, prüfen wir, ob maxLines überschritten wird
  const handleEditorChange = (newEditorState) => {
    const content = newEditorState.getCurrentContent();
    const linesCount = content.getBlockMap().size;
    const plainText = content.getPlainText();

    if (plainText.length > maxChars) {
      return; // blockieren
    }

    if (linesCount > maxLines) {
      // Abbruch: nicht aktualisieren
      return;
    }

    setEditorState(newEditorState);
  };

  const handleSave = () => {
    const contentState = editorState.getCurrentContent();
    const html = stateToHTML(contentState, {
      inlineStyles: {
        SVG_UNTERSTRICH: {
          style: {
            backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 400 20' preserveAspectRatio='none'><path d='M0,15 C50,25 150,5 200,15 C250,25 350,5 400,15' stroke='red' stroke-width='6' fill='none' stroke-linecap='round' /></svg>")`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "0 100%",
            backgroundSize: "100% 0.8em",
            paddingBottom: "0.2em",
          },
        },
      },
    });

    onSave(html);
    setIsEditing(false);
  };

  const handleCancel = () => {
    // EditorState zurücksetzen auf den ursprünglichen Inhalt
    const blocks = convertFromHTML(text || "");
    const resetContent = ContentState.createFromBlockArray(
      blocks.contentBlocks,
      blocks.entityMap
    );
    setEditorState(EditorState.createWithContent(resetContent));
    setIsEditing(false);
  };

  if (isEditing) {
    return (
      <div
        className={`relative border border-gray-300 p-2 rounded bg-white ${className}`}
        style={{
          // Verhindert horizontales Überlaufen, wrappt lange Wörter
          maxWidth: "100%",
          overflowWrap: "break-word",
          whiteSpace: "pre-wrap",
        }}
      >
        {/* Kleine Toolbar */}
        <div className="flex flex-wrap items-start gap-2 mb-2">
          {/* Linker Block (Buttons) */}
          <div className="flex gap-2 flex-shrink-0">
            <button
              onClick={() => toggleInlineStyle("BOLD")}
              className="px-1 py-0.5 text-xs bg-slate-200 rounded"
              title="Fett"
            >
              <Bold className="w-4 h-4" />
            </button>
            <button
              onClick={() => toggleInlineStyle("ITALIC")}
              className="px-1 py-0.5 text-xs bg-slate-200 rounded"
              title="Kursiv"
            >
              <Italic className="w-4 h-4" />
            </button>
            <button
              onClick={() => toggleInlineStyle("UNDERLINE")}
              className="px-1 py-0.5 text-xs bg-slate-200 rounded"
              title="Unterstrichen"
            >
              <Underline className="w-4 h-4" />
            </button>
            <button
              onClick={() => toggleInlineStyle("SVG_UNTERSTRICH")}
              className="px-1 py-0.5 text-xs bg-slate-200 rounded"
              title="SVG-Unterstrichen"
            >
              <span className="text-sm font-semibold">SVG</span>
            </button>
          </div>

          {/* Rechter Block (Clipboard) */}
          <div className="flex flex-col items-center gap-4 flex-shrink-0">
            <ClipboardButtonsBar
              full={true}
              names={["Promoter Vorname", "Promoter Nachname"]}
              classes="!flex-col flex-wrap !text-[0.75rem] md:!text-[1rem]"
            />
          </div>
        </div>

        {/* Editor-Bereich */}
        <div
          className="min-h-[100px] !text-slate-950"
          style={{
            // Editor soll Zeilen umbrechen
            overflowWrap: "break-word",
            whiteSpace: "pre-wrap",
          }}
        >
          <Editor
            editorState={editorState}
            onChange={handleEditorChange}
            customStyleMap={customStyleMap}
          />
        </div>

        {/* Buttons für Speichern/Abbrechen */}
        <div className="flex gap-2 mt-2">
          <button
            onClick={handleSave}
            className="px-2 py-1 text-xs bg-blue-500 text-white rounded"
          >
            Bestätigen
          </button>
          <button
            onClick={handleCancel}
            className="px-2 py-1 text-xs bg-gray-300 rounded"
          >
            Abbrechen
          </button>
        </div>

        {/* Optional: Edit-Icon auch im Editiermodus oben rechts */}
        <button
          onClick={handleCancel}
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
          title="Bearbeitung abbrechen"
        >
          <PencilIcon className="w-4 h-4" />
        </button>
      </div>
    );
  }

  // Anzeige-Modus
  return (
    <div className="relative" style={{ maxWidth: "100%" }}>
      <Element
        className={className}
        style={{
          overflowWrap: "break-word",
        }}
        dangerouslySetInnerHTML={{ __html: text }}
      ></Element>
      {/* Edit-Icon oben rechts */}
      <button
        onClick={() => setIsEditing(true)}
        className="absolute top-0 -right-4 text-gray-600 hover:text-gray-800"
        title="Bearbeiten"
      >
        <PencilIcon className="w-4 h-4" />
      </button>
    </div>
  );
};

export default EditableText;
