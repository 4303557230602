export const getTemplateSpecificPayload = (
  templatePayload,
  templateData,
  theme
) => {
  if (theme !== "xyz") return templatePayload; // Falls das Theme nicht "xyz" ist, keine Anpassung

  return {
    ...templatePayload,
    rating: {
      ...templatePayload.rating,
      stars: templateData.rating.stars,
      text: templateData.rating.text,
      stars2: templateData.rating.stars2,
      text2: templateData.rating.text2,
      stars3: templateData.rating.stars3,
      text3: templateData.rating.text3,
    },
    headerSection: {
      ...templatePayload.headerSection,
      header: templateData.headerSection.header,
      subheader: templateData.headerSection.subheader,
    },
    aboutSection: {
      ...templatePayload.aboutSection,
      activate: templateData.aboutSection.activate,
      header: templateData.aboutSection.header,
      subheader: templateData.aboutSection.subheader,
      subheadertext: templateData.aboutSection.subheadertext,
    },
    bannerSection: {
      ...templatePayload.bannerSection,
      activate: templateData.bannerSection.activate,
      header: templateData.bannerSection.header,
    },
    promoSection: {
      ...templatePayload.promoSection,
      header: templateData.promoSection.header,
      subheader: templateData.promoSection.subheader,
      button: templateData.promoSection.button,
    },
    cardSection: {
      ...templatePayload.cardSection,
      activate: templateData.cardSection.activate,
      header: templateData.cardSection.header,
      card1Text: templateData.cardSection.card1Text,
      card2Text: templateData.cardSection.card2Text,
      card3Text: templateData.cardSection.card3Text,
      card4Text: templateData.cardSection.card4Text,
      card5Text: templateData.cardSection.card5Text,
    },
    pictureSection: {
      ...templatePayload.pictureSection,
      header: templateData.pictureSection.header,
      activate: templateData.pictureSection.activate,
      one: {
        ...templatePayload.pictureSection?.one,
        header: templateData.pictureSection.one.header,
        text: templateData.pictureSection.one.text,
      },
      two: {
        ...templatePayload.pictureSection?.two,
        header: templateData.pictureSection.two.header,
        text: templateData.pictureSection.two.text,
      },
      three: {
        ...templatePayload.pictureSection?.three,
        header: templateData.pictureSection.three.header,
        text: templateData.pictureSection.three.text,
      },
      four: {
        ...templatePayload.pictureSection?.four,
        header: templateData.pictureSection.four.header,
        text: templateData.pictureSection.four.text,
      },
      five: {
        ...templatePayload.pictureSection?.five,
        header: templateData.pictureSection.five.header,
        text: templateData.pictureSection.five.text,
      },
    },
    // Neuer FAQ-Block
    faq: {
      one: {
        question: templateData.faq?.one?.question,
        answer: templateData.faq?.one?.answer,
      },
      two: {
        question: templateData.faq?.two?.question,
        answer: templateData.faq?.two?.answer,
      },
      three: {
        question: templateData.faq?.three?.question,
        answer: templateData.faq?.three?.answer,
      },
      four: {
        question: templateData.faq?.four?.question,
        answer: templateData.faq?.four?.answer,
      },
      five: {
        question: templateData.faq?.five?.question,
        answer: templateData.faq?.five?.answer,
      },
      six: {
        question: templateData.faq?.six?.question,
        answer: templateData.faq?.six?.answer,
      },
      seven: {
        question: templateData.faq?.seven?.question,
        answer: templateData.faq?.seven?.answer,
      },
      eight: {
        question: templateData.faq?.eight?.question,
        answer: templateData.faq?.eight?.answer,
      },
      nine: {
        question: templateData.faq?.nine?.question,
        answer: templateData.faq?.nine?.answer,
      },
      ten: {
        question: templateData.faq?.ten?.question,
        answer: templateData.faq?.ten?.answer,
      },
    },
    buttonOne: templateData.buttonOne,
    buttonTwo: templateData.buttonTwo,
    buttonThree: templateData.buttonThree,
    font: templateData.font,
  };
};
