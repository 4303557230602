import { useState, useEffect, useMemo, useRef } from "react";
import { Avatar, Rate } from "antd";
import { useLandingPage } from "../../../../context/LandingPageContext";
import EditableText from "../../shared/EditableField";
import EditableButton from "../../shared/EditableButton";
import useThemeColor from "../../../../hooks/useThemeColor";
import { useIsMobileView } from "../../../Shared/ViewToggle";

const XYZPromoSection = () => {
  const { setTemplateData, templateData } = useLandingPage();
  const [color] = useThemeColor();
  const isMobile = useIsMobileView();
  const fileInputRef = useRef(null);
  const backgroundImage =
    templateData.promoSection?.img || "/placeholder.svg?height=500&width=1000";

  const avatarUrls = useMemo(() => {
    return Array(5)
      .fill(0)
      .map((_, index) => `/ReviewPeople/${index + 1}.jfif`);
  }, []);

  // Initial countdown values
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 1,
    minutes: 50,
    seconds: 58,
  });

  // Countdown timer effect
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        let { days, hours, minutes, seconds } = prevTime;

        if (seconds > 0) {
          seconds -= 1;
        } else {
          seconds = 59;
          if (minutes > 0) {
            minutes -= 1;
          } else {
            minutes = 59;
            if (hours > 0) {
              hours -= 1;
            } else {
              hours = 23;
              if (days > 0) {
                days -= 1;
              } else {
                // Timer completed
                clearInterval(timer);
              }
            }
          }
        }

        return { days, hours, minutes, seconds };
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // Format time with leading zeros
  const formatTime = (time) => {
    return time < 10 ? `0${time}` : time;
  };

  const handleBackgroundChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const blobURL = URL.createObjectURL(file);
      setTemplateData((prev) => ({
        ...prev,
        promoSection: {
          ...prev.promoSection,
          img: blobURL,
        },
      }));
    }
  };

  const handleBackgroundClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <div className="relative w-full md:min-h-[500px] min-h-[300px] flex items-center justify-center overflow-hidden">
      {/* Background image with overlay */}
      <div
        className="absolute inset-0 z-0 cursor-pointer"
        onClick={handleBackgroundClick}
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          backgroundBlendMode: "overlay",
        }}
      />
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        onChange={handleBackgroundChange}
        style={{ display: "none" }}
      />

      {/* Content container */}
      <div className="relative z-10 max-w-full mx-auto px-8 py-16 text-center text-white">
        {/* Main heading */}
        <EditableText
          text={templateData.promoSection?.header}
          maxLines={1}
          maxChars={100}
          onSave={(newValue) =>
            setTemplateData((prev) => ({
              ...prev,
              promoSection: {
                ...prev.promoSection,
                header: newValue,
              },
            }))
          }
          element="h1"
          className="text-4xl md:text-5xl font-bold mb-6"
        />

        {/* Subheading with strikethrough text */}
        <EditableText
          text={templateData.promoSection?.subheader}
          maxLines={2}
          maxChars={200}
          onSave={(newValue) =>
            setTemplateData((prev) => ({
              ...prev,
              promoSection: {
                ...prev.promoSection,
                subheader: newValue,
              },
            }))
          }
          element="p"
          className="text-lg md:text-xl mb-8"
        />

        {/* Countdown timer */}
        <div className="flex justify-center gap-4 mb-10">
          {[
            { value: formatTime(timeLeft.days), label: "Tage" },
            { value: formatTime(timeLeft.hours), label: "Stunden" },
            { value: formatTime(timeLeft.minutes), label: "Minuten" },
            { value: formatTime(timeLeft.seconds), label: "Sekunden" },
          ].map((item, index) => (
            <div key={index} className="flex flex-col items-center">
              <div className="text-4xl font-bold">{item.value}</div>
              <div className="text-sm">{item.label}</div>
            </div>
          ))}
        </div>

        {/* Custom CTA Button without using Ant Design Button */}
        <EditableButton
          text={templateData.promoSection?.button}
          onSave={(newValue) =>
            setTemplateData((prev) => ({
              ...prev,
              promoSection: {
                ...prev.promoSection,
                button: newValue,
              },
            }))
          }
          className={`text-white ${
            isMobile
              ? "px-8 py-4 md:px-8 md:py-4"
              : "px-8 py-4 md:px-8 md:py-4 md:text-xl shadow-2xl"
          } rounded-full hover:opacity-90 transition-opacity text-xl relative`}
          style={{
            background: color,
            boxShadow: `inset 2px 2px 5px rgba(255, 255, 255, 0.4), 
                inset -2px -2px 5px rgba(0, 0, 0, 0.2),
                4px 4px 10px rgba(0, 0, 0, 0.3)`,
          }}
        />

        {/* Testimonials section */}
        <div className="mt-12 flex flex-col items-center">
          {/* Rating */}
          <div className="flex flex-row items-center">
            <div className="flex">
              <Avatar.Group>
                {avatarUrls.map((url, index) => (
                  <Avatar
                    key={index}
                    src={url}
                    style={{
                      width: "2.8rem", // 5rem entspricht z. B. 80px bei 16px Root-Fontgröße
                      height: "2.8rem",
                      fontSize: "2.8rem", // Falls Initialen genutzt werden
                      backgroundColor: "#f0f0f0",
                      marginLeft: index === 0 ? "0" : "-15px",
                    }}
                  />
                ))}
              </Avatar.Group>
            </div>
            <div className="flex items-start flex-col gap-2">
              <div className="flex">
                <Rate
                  allowHalf
                  count={5}
                  style={{ fontSize: "1.2rem" }}
                  value={templateData.rating?.stars3 || 0}
                  onChange={(value) =>
                    setTemplateData((prev) => ({
                      ...prev,
                      rating: {
                        ...prev.rating,
                        stars3: value,
                      },
                    }))
                  }
                />
              </div>
              {/* Hier EditableText für rating.text */}
              <span className="ml-2 text-md inline-flex justify-start">
                <EditableText
                  text={templateData.rating?.text3}
                  maxLines={1}
                  onSave={(newValue) =>
                    setTemplateData((prev) => ({
                      ...prev,
                      rating: {
                        ...prev.rating,
                        text3: newValue,
                      },
                    }))
                  }
                  element="span"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default XYZPromoSection;
