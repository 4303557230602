import React from "react";
import { motion } from "framer-motion";
import { ShieldIcon, PlusCircleIcon, MinusCircleIcon } from "lucide-react";
import EditableText from "../../shared/EditableField";
import { useLandingPage } from "../../../../context/LandingPageContext";
import ImagePicker from "../../shared/3DIconPicker";
import { fontMapping } from "../../shared/fontMapping";
import { useIsMobileView } from "../../../Shared/ViewToggle";

// Fallback-Bild für neue Sektionen
const DEFAULT_IMAGE = "https://placehold.co/400x300@2x.png";

const XYZPictureSection = () => {
  const { setTemplateData, templateData } = useLandingPage();
  const { pictureSection } = templateData;
  const isMobile = useIsMobileView();
  console.log(pictureSection);

  const sectionVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  // Reihenfolge der möglichen Sektionen
  const sectionOrder = ["one", "two", "three", "four", "five"];

  // Nur die Sektionen anzeigen, die bereits befüllt sind
  const activeSections = sectionOrder.filter((key) => {
    const section = pictureSection[key];
    return (
      section && (section.header || section.text || section.img || section.icon)
    );
  });

  // Neue Sektion hinzufügen
  const handleAddSection = () => {
    const nextKey = sectionOrder.find((key) => {
      const section = pictureSection[key];
      return !(
        section &&
        (section.header || section.text || section.img || section.icon)
      );
    });

    if (nextKey) {
      setTemplateData((prev) => ({
        ...prev,
        pictureSection: {
          ...prev.pictureSection,
          [nextKey]: {
            img: DEFAULT_IMAGE,
            header: "Neue Überschrift",
            text: "Neuer Text",
            icon: "Shield",
          },
        },
      }));
    }
  };

  // Sektion entfernen
  const handleRemoveSection = (key) => {
    setTemplateData((prev) => {
      const updated = { ...prev.pictureSection };
      delete updated[key];
      return { ...prev, pictureSection: updated };
    });
  };

  // Klick auf das Bild -> File-Dialog öffnen
  const handleImageClick = (key) => {
    const input = document.getElementById(`file-input-${key}`);
    if (input) {
      input.click();
    }
  };

  // **Nur lokales Vorschaubild** erstellen & speichern, kein Cloud-Upload
  const handleImageChange = (event, key) => {
    const fileList = event.target.files;
    if (!fileList || fileList.length === 0) return;

    const file = fileList[0];
    // Lokale URL für die Vorschau
    const localUrl = URL.createObjectURL(file);

    // Wir speichern nur das Vorschaubild in `templateData`
    // (Optional kannst du hier auch den File-Objekt speichern,
    //  um ihn später an anderer Stelle hochzuladen.)
    setTemplateData((prev) => ({
      ...prev,
      pictureSection: {
        ...prev.pictureSection,
        [key]: {
          ...prev.pictureSection[key],
          img: localUrl,
          // optional: file
        },
      },
    }));
  };

  return (
    <>
      {pictureSection?.activate && (
        <motion.section
          className={`w-full py-8 px-8 ${
            isMobile ? "space-y-6" : "md:px-24 lg:px-36 space-y-12"
          } bg-slate-100 dark:bg-slate-800 override-font`}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
          transition={{ duration: 0.8 }}
          variants={sectionVariants}
          style={{
            "--font-family":
              fontMapping[templateData.font] || fontMapping.modern,
          }}
        >
          <div className="w-full flex items-center justify-center">
            <EditableText
              text={pictureSection.header}
              maxLines={2}
              maxChars={200}
              onSave={(newValue) =>
                setTemplateData((prev) => ({
                  ...prev,
                  pictureSection: { ...prev.pictureSection, header: newValue },
                }))
              }
              element="h1"
              className={`font-bold mb-6 md:mb-8 ${
                isMobile ? "text-3xl" : "text-4xl"
              } mx-auto group relative text-center`}
            />
          </div>

          {activeSections.map((key, index) => {
            const item = pictureSection[key];
            const imageSrc = item.img || DEFAULT_IMAGE;

            return (
              <div key={key} className="relative">
                {/* Entfernen-Button nur für dynamische Sektionen */}
                {!["one", "two"].includes(key) && (
                  <button
                    onClick={() => handleRemoveSection(key)}
                    className="absolute -top-4 -left-4 z-10"
                  >
                    <MinusCircleIcon className="w-8 h-8 text-red-600" />
                  </button>
                )}

                {/* Layout: Bild links/rechts je nach Index */}
                <div
                  className={`flex flex-col ${
                    !isMobile
                      ? index % 2 === 0
                        ? "md:flex-row"
                        : "md:flex-row-reverse"
                      : ""
                  } items-center gap-12`}
                >
                  {/* Textblock */}
                  <div
                    className={`w-full md:w-1/2 ${isMobile ? "!w-full" : ""}`}
                  >
                    <EditableText
                      maxLines={2}
                      maxChars={200}
                      text={item.header}
                      onSave={(newValue) =>
                        setTemplateData((prev) => ({
                          ...prev,
                          pictureSection: {
                            ...prev.pictureSection,
                            [key]: {
                              ...prev.pictureSection[key],
                              header: newValue,
                            },
                          },
                        }))
                      }
                      element="h2"
                      className="font-bold mb-4 text-5xl md:text-5xl"
                    />
                    <EditableText
                      maxLines={3}
                      maxChars={300}
                      text={item.text}
                      onSave={(newValue) =>
                        setTemplateData((prev) => ({
                          ...prev,
                          pictureSection: {
                            ...prev.pictureSection,
                            [key]: {
                              ...prev.pictureSection[key],
                              text: newValue,
                            },
                          },
                        }))
                      }
                      element="p"
                      className="text-gray-600 dark:text-gray-300 text-lg md:text-xl"
                    />
                  </div>

                  {/* Bild */}
                  <div
                    className={`w-full md:w-1/2 ${
                      isMobile ? "!w-full" : ""
                    } relative`}
                  >
                    <div className="bg-white dark:bg-slate-500 rounded-md p-8">
                      <img
                        src={imageSrc}
                        alt="Bild"
                        className="rounded-lg w-full aspect-[4/3] object-cover cursor-pointer"
                        onClick={() => handleImageClick(key)}
                      />
                    </div>

                    {/* Unsichtbares File-Input */}
                    <input
                      id={`file-input-${key}`}
                      type="file"
                      accept="image/*"
                      className="hidden"
                      onChange={(e) => handleImageChange(e, key)}
                    />

                    {/* 3D-IconPicker (falls du Icons austauschen möchtest) */}
                    <div className="absolute -top-8 -right-8">
                      <ImagePicker
                        selectedImage={item.icon || "shield.png"}
                        onChange={(newImageName) =>
                          setTemplateData((prev) => ({
                            ...prev,
                            pictureSection: {
                              ...prev.pictureSection,
                              [key]: {
                                ...prev.pictureSection[key],
                                icon: newImageName,
                              },
                            },
                          }))
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          {/* Neue Sektion hinzufügen (bis max. 5) */}
          {activeSections.length < 5 && (
            <button
              onClick={handleAddSection}
              className="flex items-center justify-center w-full py-4 text-blue-600 dark:text-blue-400 hover:text-blue-800 transition"
            >
              <PlusCircleIcon className="w-8 h-8" />
            </button>
          )}
        </motion.section>
      )}
    </>
  );
};

export default XYZPictureSection;
