import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { PopUpComponent } from "../../../ConfirmPopup";
import Inputs from "../../../_NEW/shared/Input";
import Box from "../../../_NEW/LandingPageConfig/Layout/Box";
import Button from "../../../_NEW/LandingPageConfig/shared/Button";
import MediaUpload from "../../../_NEW/LandingPageConfig/shared/MediaUpload";
import { useLandingPage } from "../../context/LandingPageContext";
import ClosingFormularKonfig from "./ClosingFormularKonfig";

const MAX_QUESTIONS = 8;

const QuestionSettings = () => {
  const { id: landingpageId } = useParams();
  const { questionData, setQuestionData } = useLandingPage();
  const imageRefs = useRef([]);

  useEffect(() => {
    imageRefs.current = Array.from(
      { length: questionData.length },
      (_, index) => imageRefs.current[index]
    );
  }, [questionData]);

  const updateQuestion = (index, newData) => {
    setQuestionData((prevQuestions) =>
      prevQuestions.map((q, i) => (i === index ? { ...q, ...newData } : q))
    );
  };

  const updateAnswer = (questionIndex, answerIndex, newData) => {
    setQuestionData((prevQuestions) =>
      prevQuestions.map((q, i) =>
        i === questionIndex
          ? {
              ...q,
              answerOptions: q.answerOptions.map((option, j) =>
                j === answerIndex ? { ...option, ...newData } : option
              ),
            }
          : q
      )
    );
  };

  const addAnswer = (questionIndex) => {
    setQuestionData((prevQuestions) =>
      prevQuestions.map((q, i) =>
        i === questionIndex
          ? {
              ...q,
              answerOptions: [
                ...q.answerOptions,
                { text: "", consideredNiceToHaveAnswer: true },
              ],
            }
          : q
      )
    );
  };

  const deleteAnswer = (questionIndex, answerIndex) => {
    setQuestionData((prevQuestions) =>
      prevQuestions.map((q, i) =>
        i === questionIndex
          ? {
              ...q,
              answerOptions: q.answerOptions.filter(
                (_, j) => j !== answerIndex
              ),
            }
          : q
      )
    );
  };

  const deleteQuestion = (questionIndex) => {
    PopUpComponent({
      onConfirm: () => {
        setQuestionData((prevQuestions) =>
          prevQuestions.filter((_, i) => i !== questionIndex)
        );
      },
      heading: "Bist Du sicher, dass Du diese Frage löschen möchtest?",
      confirmButtonText: "Löschen",
    });
  };

  const addQuestion = () => {
    if (questionData.length < MAX_QUESTIONS) {
      setQuestionData((prevQuestions) => [
        ...prevQuestions,
        {
          user_id: null,
          landingpage_id: landingpageId,
          questionText: "Neue Frage hier eingeben",
          backgroundImage: "/landingPageBgs/finance.svg",
          answerOptions: [
            { text: "Positive Antwort", consideredNiceToHaveAnswer: true },
            { text: "Negative Antwort", consideredNiceToHaveAnswer: false },
          ],
        },
      ]);
    }
  };

  return (
    <div className="flex flex-col items-center p-4 gap-4 text-slate-900">
      <h3 className="text-2xl font-semibold mb-4 text-slate-950">
        Bearbeiten Sie Ihre Fragen
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 w-full">
        {questionData.map((question, id) => (
          <Question
            key={id}
            title={`Frage ${id + 1}`}
            question={question}
            onChangeQuestionText={(e) =>
              updateQuestion(id, { questionText: e.target.value })
            }
            onChangeAnswer={(value, answerId) =>
              updateAnswer(id, answerId, { text: value })
            }
            onChangeNiceToHave={(value, answerId) =>
              updateAnswer(id, answerId, { consideredNiceToHaveAnswer: value })
            }
            onDeleteAnswer={(answerId) => deleteAnswer(id, answerId)}
            addAnswer={() => addAnswer(id)}
            deleteQuestion={() => deleteQuestion(id)}
            imageRef={(ref) => {
              imageRefs.current[id] = ref;
            }}
            backgroundImage={question.backgroundImage}
            setBackgroundImage={(newBackgroundImage) =>
              updateQuestion(id, { backgroundImage: newBackgroundImage })
            }
          />
        ))}
      </div>
      <Button
        onClick={addQuestion}
        text={"+ Frage Hinzufügen"}
        light
        display={questionData.length < MAX_QUESTIONS}
        className="mt-6"
      />
      <ClosingFormularKonfig visible={true} />
    </div>
  );
};

const Question = ({
  title,
  question,
  onChangeQuestionText,
  onChangeAnswer,
  onChangeNiceToHave,
  onDeleteAnswer,
  addAnswer,
  deleteQuestion,
  imageRef,
  backgroundImage,
  setBackgroundImage,
}) => {
  const { questionText, answerOptions } = question;

  return (
    <Box className="p-4 bg-white shadow-md rounded-lg dark:bg-gray-800 my-4">
      <h3 className="text-lg font-semibold mb-4 text-slate-950">{title}</h3>
      <Inputs
        label="Frage"
        value={questionText}
        onChange={onChangeQuestionText}
        type="textarea"
      />
      <MediaUpload
        url={backgroundImage}
        setUrl={setBackgroundImage}
        id={`image-upload-${title}`}
        textSrcThere="Hintergrundbild ändern"
        textSrcNotThere="Hintergrundbild hochladen"
        ref={imageRef}
      />
      <div className="grid gap-4 my-4">
        {answerOptions.map(({ consideredNiceToHaveAnswer, text }, id) => (
          <Inputs
            key={id}
            consideredNiceToHaveAnswer={consideredNiceToHaveAnswer}
            value={text}
            label={
              consideredNiceToHaveAnswer
                ? "Antwortmöglichkeit"
                : "Antwortmöglichkeit (ablehnend)"
            }
            onChange={(e) => onChangeAnswer(e.target.value, id)}
            onChangeNiceToHave={(value) => onChangeNiceToHave(value, id)}
            withDropdown={true}
            onDeleteAnswer={() => onDeleteAnswer(id)}
          />
        ))}
      </div>
      {/* Buttons untereinander */}
      <div className="flex flex-col gap-2">
        <Button onClick={addAnswer} text={"+ Antwort hinzufügen"} light />
        <Button onClick={deleteQuestion} text={"Frage Löschen"} danger />
      </div>
    </Box>
  );
};

export default QuestionSettings;
