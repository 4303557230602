import React, { useState, useRef } from "react";
import { useLandingPage } from "../../context/LandingPageContext";
import { Modal, Switch, Input, Tooltip } from "antd";
import Inputs from "../../../_NEW/shared/Input";
import { EditOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import EditableText from "../templates/shared/EditableField";
import { useIsMobileView } from "./ViewToggle";

const ClosingFormularKonfig = ({ visible }) => {
  const { landingPageData, setLandingPageData } = useLandingPage();
  const [privacy, setPrivacy] = useState(false);
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const isMobile = useIsMobileView();

  // Modal States
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const fileInputRef = useRef(null);

  const handleBackgroundClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Öffnet Datei-Upload
    }
  };

  const handleBackgroundChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const blobURL = URL.createObjectURL(file);
      updateLandingPageData("background_image", blobURL);
    }
  };

  // Öffnet ein Modal mit individuellem Inhalt
  const openModal = (type) => {
    setModalType(type);
    setIsModalOpen(true);
  };

  // Aktualisiert die LandingPage-Einstellungen
  const updateLandingPageData = (field, value) => {
    setLandingPageData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    visible && (
      <div className="relative w-screen min-h-screen py-5 overflow-scroll">
        <div
          className="absolute inset-0 z-0 hover:cursor-pointer"
          onClick={handleBackgroundClick}
          style={{
            backgroundImage: `url(${landingPageData?.background_image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <input
          type="file"
          accept="image/*"
          ref={fileInputRef}
          onChange={handleBackgroundChange}
          style={{ display: "none" }}
        />
        <div className="relative z-10 top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] flex items-center justify-center py-5">
          <div
            className="w-[90%] xs:w-[80%] p-5 bg-white rounded-sm max-w-[1000px] overflow-scroll"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="max-w-[800px] m-auto flex flex-col gap-5">
              <div className="flex flex-col gap-4 p-8">
                {/* Positiver Verlauf */}
                <div className="flex items-start gap-2">
                  <Tooltip title="Dieser Text wird angezeigt, wenn Fragen positiv verlaufen">
                    <QuestionCircleOutlined className="mt-1 text-blue-500" />
                  </Tooltip>
                  <EditableText
                    maxLines={2}
                    text={landingPageData.aufgrundDeinerAngaben}
                    onSave={(newValue) =>
                      updateLandingPageData("aufgrundDeinerAngaben", newValue)
                    }
                    element="h2"
                    className={`font-bold mb-2 ${
                      isMobile ? "text-xl" : "text-4xl"
                    }`}
                  />
                </div>

                {/* Negativer Verlauf */}
                <div className="flex items-start gap-2">
                  <Tooltip title="Dieser Text wird angezeigt, wenn Fragen negativ verlaufen">
                    <QuestionCircleOutlined className="mt-1 text-red-500" />
                  </Tooltip>
                  <EditableText
                    maxLines={2}
                    text={landingPageData.negativeText}
                    onSave={(newValue) =>
                      updateLandingPageData("negativeText", newValue)
                    }
                    element="h2"
                    className={`font-bold mb-2 ${
                      isMobile ? "text-xl" : "text-4xl"
                    }`}
                  />
                </div>
              </div>

              {/* Eingabefelder */}
              <div className="flex flex-col gap-5 md:flex-row">
                <Inputs
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  label="Dein Name"
                />
                <Inputs
                  onChange={(e) => setNumber(e.target.value)}
                  value={number}
                  label="Mobilnummer"
                  tel
                />
              </div>

              <Datenschutz checkbox={privacy} setCheckbox={setPrivacy} />

              {/* Buttons öffnen Modal mit Konfigurationen */}
              <div className="flex flex-col gap-2 md:flex-row">
                <Button
                  text={
                    landingPageData.whatsappButtonTextTwo ||
                    "WhatsApp schreiben"
                  }
                  backgroundColor="#25D366"
                  onClick={() => openModal("whatsapp")}
                />
                <Button
                  text="Termin aussuchen"
                  backgroundColor="#0084F7"
                  onClick={() => openModal("calendly")}
                />
                <Button
                  text="Rückrufbitte"
                  backgroundColor="#FFD814"
                  textColor="black"
                  onClick={() => openModal("recall")}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Modal für Konfiguration */}
        <Modal
          title="Einstellungen"
          open={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          footer={[
            <Button
              key="close"
              text="Schließen"
              onClick={() => setIsModalOpen(false)}
            />,
          ]}
        >
          {modalType && (
            <div className="flex flex-col gap-3">
              {modalType === "whatsapp" && (
                <>
                  <div className="flex justify-between items-center">
                    <label>WhatsApp aktivieren</label>
                    <Switch
                      checked={landingPageData.activateWhatsApp}
                      onChange={(checked) =>
                        updateLandingPageData("activateWhatsApp", checked)
                      }
                      size="small"
                    />
                  </div>
                  <label>WhatsApp-Nummer</label>
                  <Input
                    value={landingPageData.whatsappNumber}
                    onChange={(e) =>
                      updateLandingPageData("whatsappNumber", e.target.value)
                    }
                  />
                  <label>WhatsApp Button Text (Version 2)</label>
                  <Input
                    value={landingPageData.whatsappButtonTextTwo}
                    onChange={(e) =>
                      updateLandingPageData(
                        "whatsappButtonTextTwo",
                        e.target.value
                      )
                    }
                  />
                  <label>WhatsApp Nachricht</label>
                  <Input.TextArea
                    value={landingPageData.whatsappText}
                    onChange={(e) =>
                      updateLandingPageData("whatsappText", e.target.value)
                    }
                  />
                </>
              )}

              {modalType === "calendly" && (
                <>
                  <div className="flex justify-between items-center">
                    <label>Calendly aktivieren</label>
                    <Switch
                      checked={landingPageData.activateCalendly}
                      onChange={(checked) =>
                        updateLandingPageData("activateCalendly", checked)
                      }
                      size="small"
                    />
                  </div>
                  <label>Calendly-Link</label>
                  <Input
                    value={landingPageData.calendlyLink}
                    onChange={(e) =>
                      updateLandingPageData("calendlyLink", e.target.value)
                    }
                  />
                </>
              )}

              {modalType === "recall" && (
                <>
                  <div className="flex justify-between items-center">
                    <label>Rückruf aktivieren</label>
                    <Switch
                      checked={landingPageData.activate_recall}
                      onChange={(checked) =>
                        updateLandingPageData("activate_recall", checked)
                      }
                      size="small"
                    />
                  </div>
                </>
              )}
            </div>
          )}
        </Modal>
      </div>
    )
  );
};

export default ClosingFormularKonfig;

const Button = ({
  backgroundColor = "grey",
  text,
  textColor = "white",
  onClick,
}) => (
  <div className="relative w-full group">
    {/* Edit-Icon oben rechts */}
    <div className="absolute top-1 right-1 z-10">
      <EditOutlined
        className="text-white bg-black/50 rounded-full p-1 group-hover:scale-110 transition-transform"
        style={{ fontSize: "18px" }}
      />
    </div>

    {/* Klickbarer Button */}
    <button
      onClick={onClick}
      style={{ background: backgroundColor, color: textColor }}
      className="w-full p-3 font-semibold rounded-sm md:p-4 text-md md:text-lg"
    >
      {text}
    </button>
  </div>
);

const Datenschutz = ({ checkbox, setCheckbox }) => (
  <>
    <div className="font-semibold text-[14px]">
      Diese Daten werden nur für die Erstkontaktaufnahme genutzt und können auf
      Wunsch gelöscht werden.
    </div>
    <div className="flex items-center gap-3">
      <input
        required
        type="checkbox"
        checked={checkbox}
        onChange={(e) => setCheckbox(e.target.checked)}
        className="accent-blue-500 scale-110"
      />
      <p className="font-semibold text-[14px]">
        Ich stimme der Datenschutzerklärung zu
      </p>
    </div>
  </>
);
