import { message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useLandingPage } from "../../../../context/LandingPageContext";
import { useParams } from "react-router-dom";
import { useMessageContext } from "../../../../../../context/messageContext";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../../../redux/auth/selectors";
import { aboTarif } from "../../../../../../utils/divers";
import { PopUpComponent } from "../../../../../ConfirmPopup";
import Inputs from "../../../../../_NEW/shared/Input";
import Box from "../../../../../_NEW/LandingPageConfig/Layout/Box";
import Button from "../../../../../_NEW/LandingPageConfig/shared/Button";
import MediaUpload from "../../../../../_NEW/LandingPageConfig/shared/MediaUpload";
import localReviewService from "../../../../../../service/LocalReviewService";
import { useIsMobileView } from "../../../Shared/ViewToggle";
import { fontMapping } from "../../shared/fontMapping";

const MIN = 3;
const MAX = 12;

const LocalReviewSection = ({ registerMediaUploadRef }) => {
  const { landingPageData, templateData, localReviews, setLocalReviews } =
    useLandingPage();
  const { user } = useSelector(selectUser);
  const { id: landingpageId } = useParams();
  const { showMessage } = useMessageContext();
  const isMobile = useIsMobileView();
  console.log(landingPageData);
  const [isActivateLocalReviews, setIsActivateLocalReviews] = useState(
    landingPageData?.activateLocalReviews || false
  );

  const [shouldScroll, setShouldScroll] = useState(false);

  const containerRef = useRef(null);

  useEffect(() => {
    const getReviews = async () => {
      try {
        const res = await localReviewService.list(landingPageData._id);
        console.log("Fetched Reviews:", res.data);
        setLocalReviews(res.data);
      } catch (error) {
        console.error("Error fetching reviews:", error);
        showMessage("error", "Fehler beim Laden der Testimonials");
      }
    };
    if (landingPageData && landingPageData._id) {
      getReviews();
    }
  }, [landingPageData, showMessage, setLocalReviews]);

  useEffect(() => {
    if (shouldScroll && containerRef.current) {
      containerRef.current.scrollLeft = containerRef.current.scrollWidth;
      setShouldScroll(false);
    }
  }, [shouldScroll]);

  const addReview = () => {
    if (localReviews.length >= MAX - 1) {
      showMessage("info", "Maximale Anzahl an Testimonials erreicht.");
      return;
    }
    setLocalReviews([
      ...localReviews,
      {
        reviewedby: "",
        stars: 5,
        comment: "",
        customerImage: "",
        landingpage_id: landingpageId,
      },
    ]);
  };

  const updateReview = (index, updatedData) => {
    const updatedReviews = localReviews.map((review, i) =>
      i === index ? { ...review, ...updatedData } : review
    );
    setLocalReviews(updatedReviews);
  };

  const deleteReview = (index) => {
    if (localReviews.length <= MIN) {
      setIsActivateLocalReviews(false);
    }
    if (localReviews.length === MIN) {
      showMessage(
        "info",
        `Testimonials deaktiviert: Bitte mindestens ${MIN} Testimonials anlegen.`
      );
    }
    const updatedReviews = localReviews.filter((_, i) => i !== index);
    setLocalReviews(updatedReviews);
  };

  return (
    <Box
      className={
        templateData.darkMode
          ? "bg-slate-600 !rounded-none"
          : "!bg-[#E0E9F1] !rounded-none"
      }
    >
      <div className="w-full items-center justify-center">
        <h2 className="font-bold mb-6 md:mb-8 text-4xl text-center">
          Das sagen unsere Kunden
        </h2>
      </div>
      <div
        ref={containerRef}
        className={`mt-10 override-font ${
          isMobile
            ? "flex flex-nowrap gap-4 overflow-x-auto"
            : "flex flex-nowrap gap-4 overflow-x-auto sm:grid-cols-2 lg:grid-cols-3 sm:gap-[17.5px]"
        }`}
        style={{
          "--font-family": fontMapping[templateData.font] || fontMapping.modern,
        }}
      >
        {localReviews.map((review, index) => (
          <LocalReviewEditCard
            key={index}
            activateLocalReviews={isActivateLocalReviews}
            comment={review}
            onChangeComment={(e) =>
              updateReview(index, { comment: e.target.value })
            }
            onChangeName={(e) =>
              updateReview(index, { reviewedby: e.target.value })
            }
            onDelete={() => {
              PopUpComponent({
                onConfirm: () => deleteReview(index),
                heading:
                  "Bist Du sicher, dass Du dieses Testimonial löschen möchtest?",
                confirmButtonText: "Löschen",
              });
            }}
            imageRef={(ref) => {
              if (registerMediaUploadRef) {
                registerMediaUploadRef(index, ref);
              }
            }}
            imageUrl={review.customerImage}
            setImageUrl={(newImageUrl) =>
              updateReview(index, { customerImage: newImageUrl })
            }
          />
        ))}
        <Button
          text="+"
          className={`${
            localReviews.length >= MAX
              ? "hidden"
              : "inline-flex justify-center items-center"
          }`}
          onClick={() => {
            addReview();
            setShouldScroll(true);
          }}
        />
      </div>
    </Box>
  );
};

const LocalReviewEditCard = ({
  activateLocalReviews,
  onChangeName,
  onChangeComment,
  comment,
  onDelete,
  imageUrl,
  setImageUrl,
  imageRef,
}) => {
  const { templateData } = useLandingPage();
  return (
    <div
      className={`flex flex-col rounded-11xl bg-white ${
        templateData.darkMode ? "text-slate-950" : ""
      } p-[20px] gap-[20px]`}
    >
      <div className="flex gap-[20px] items-center justify-left">
        <MediaUpload
          ref={imageRef}
          type="avatar"
          url={imageUrl}
          setUrl={setImageUrl}
          id={`avatar-img-${Math.random()}`}
        />
        <div className="text-[16px] font-semibold text-[#000000]">
          Bild hochladen
        </div>
        <div
          className="ml-auto text-[40px] font-bold cursor-pointer"
          onClick={onDelete}
        >
          -
        </div>
      </div>
      <Inputs
        label={"Name des Rezensionverfassers"}
        value={comment.reviewedby}
        onChange={onChangeName}
        emptyCheck={activateLocalReviews}
      />
      <Inputs
        type="textarea"
        label={"Kommentar"}
        value={comment.comment}
        onChange={onChangeComment}
        emptyCheck={activateLocalReviews}
      />
    </div>
  );
};

export default LocalReviewSection;
