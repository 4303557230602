import { Tooltip } from "antd";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import Inputs from "../../../components/_NEW/shared/Input";
import Box from "../Layout/Box";
import CollapseItem from "../shared/CollapseItem";

const Rechtliches = forwardRef(
  ({ landingPageData: { specificImpressumText } }, ref) => {
    const [impressumState, setImpressumState] = useState(specificImpressumText);

    useImperativeHandle(ref, () => ({
      async save() {
        console.log("Rechtliches.save()");
        return {
          landingPageData: {
            specificImpressumText: impressumState,
          },
        };
      },
    }));

    return (
      <Box>
        <CollapseItem
          title={"Rechtliches"}
          icon={
            <Tooltip title="Der Landingpage wird automatisch Dein im Konto hinterlegtes Impressum hinterlegt. Hier bitte nur ausfüllen, wenn Du für diese Seite ein abweichendes Impressum benötigst.">
              <span className="border-[1px] border-[#d8d8d8] p-2 rounded-[10px] mx-2">
                <AiOutlineInfoCircle />
              </span>
            </Tooltip>
          }
        >
          <Inputs
            type="textarea"
            label={"Abweichendes Impressum"}
            value={impressumState}
            onChange={(e) => setImpressumState(e.target.value)}
            emptyCheckWithText={false}
          />
        </CollapseItem>
      </Box>
    );
  }
);

export default Rechtliches;
