import { LoadingOutlined } from "@ant-design/icons";
import { Spin, Divider } from "antd";
import React, {
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "./LandingPageConfig/Layout/Header";
import Rechtliches from "./LandingPageConfig/Sections/Rechtliches";
import NameConfig from "./LandingPageConfig/Sections/NameConfig";
import Pramien from "./LandingPageConfig/Sections/Pramien";
import BegleitTextConfig from "./LandingPageConfig/Sections/BegleitTextConfig";
import TemplateXyzConfig from "./LandingPageConfig/Sections/templateConfig/TemplateXyzConfig";
import HeroConfig from "./LandingPageConfig/Sections/HeroConfig";
import IntroductionToClient from "./LandingPageConfig/Sections/IntroductionToClient";
import Button from "./LandingPageConfig/shared/Button";
import { useMessageContext } from "../context/messageContext";
import { setLoading } from "../redux/auth/actions";
import { useSelector } from "react-redux";
import { selectUser } from "../redux/auth/selectors";
import { store } from "../redux/store";
import LandingPageService from "../service/LandingPageService";
import localReviewService from "../service/LocalReviewService";
import QuestionService from "../service/QuestionService";
import { useLandingPage } from "../components/New_LP_Configuration/context/LandingPageContext";
import { getTemplateSpecificPayload } from "./LandingPageConfig/helpers/getTemplateSpecificPayload";
import UploadToCloudService from "../service/UploadToCloudService";

const LandingPageConfig = forwardRef(({ onClose, isLoading }, ref) => {
  const { type, id: landingpageId } = useParams();
  const navigate = useNavigate();
  const { showMessage, clearMessage } = useMessageContext();
  const {
    landingPageData,
    setLandingPageData,
    setQuestionData,
    templateData,
    setTemplateData,
    localReviews,
    setLocalReviews,
    questionData,
  } = useLandingPage();
  const [isSaving, setIsSaving] = useState(false);
  const { user } = useSelector(selectUser);

  // Section-Refs der anderen Komponenten
  const rechtlichesRef = useRef(null);
  const pramienRef = useRef(null);
  const nameConfigRef = useRef(null);
  const begleittextRef = useRef(null);
  const templateXyzRef = useRef(null);
  const heroConfigRef = useRef(null);
  const introductionToClient = useRef(null);

  const saveAll = async () => {
    console.log("saveAll() gestartet.");
    setIsSaving(true);
    store.dispatch(setLoading(true));
    showMessage("loading", "Speichern", 0, "lp-config-loading");

    try {
      console.log("Speichervorgang beginnt...");

      // Speichervorgang aller anderen Sektionen
      const promiseArray = [
        rechtlichesRef.current.save(),
        pramienRef.current.save(),
        nameConfigRef.current.save(),
        begleittextRef.current.save(),
        templateXyzRef.current.save(),
        heroConfigRef.current.save(),
        introductionToClient.current.save(),
      ];

      const allNewValues = await Promise.all(promiseArray);
      console.log("Alle Komponenten wurden gespeichert.", allNewValues);

      // 👇 Hintergrundbild prüfen und ggf. hochladen
      let updatedBackgroundImage = landingPageData.background_image;

      if (
        updatedBackgroundImage &&
        updatedBackgroundImage.startsWith("blob:")
      ) {
        console.log("🌄 Hintergrundbild ist ein Blob – wird hochgeladen...");

        const base64Image = await fetch(updatedBackgroundImage)
          .then((res) => res.blob())
          .then(
            (blob) =>
              new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
              })
          );

        if (base64Image) {
          const uploadedImage = await UploadToCloudService.uploadBase64Image(
            [base64Image],
            "image"
          );

          if (
            Array.isArray(uploadedImage) &&
            uploadedImage.length > 0 &&
            uploadedImage[0].secure_url
          ) {
            updatedBackgroundImage = uploadedImage[0].secure_url;
            console.log(
              "✅ Hintergrundbild erfolgreich hochgeladen:",
              updatedBackgroundImage
            );
            setLandingPageData((prev) => ({
              ...prev,
              background_image: updatedBackgroundImage,
            }));
          } else {
            console.warn(
              "⚠️ Upload fehlgeschlagen, Hintergrundbild bleibt leer."
            );
            updatedBackgroundImage = "";
          }
        }
      }

      console.log(updatedBackgroundImage);
      // LandingPage-Payload erstellen (inkl. WhatsApp, Calendly, Recall)
      const landingPagePayload = allNewValues.reduce(
        (accumulator, currentObject) => ({
          ...accumulator,
          ...(currentObject?.landingPageData || {}),
        }),
        {
          activateWhatsApp: landingPageData.activateWhatsApp,
          whatsappNumber: landingPageData.whatsappNumber,
          whatsappButtonTextTwo: landingPageData.whatsappButtonTextTwo,
          whatsappText: landingPageData.whatsappText,

          activateCalendly: landingPageData.activateCalendly,
          calendlyLink: landingPageData.calendlyLink,

          activate_recall: landingPageData.activate_recall,

          aufgrundDeinerAngaben: landingPageData.aufgrundDeinerAngaben,
          negativeText: landingPageData.negativeText,
        }
      );

      console.log("📌 Aktualisiertes LandingPage-Payload:", landingPagePayload);

      if (updatedBackgroundImage !== "") {
        landingPagePayload.background_image = updatedBackgroundImage;
      }

      console.log(
        "📌 FINAL LandingPagePayload:",
        landingPagePayload.background_image
      );

      // **LocalReviews Bilder hochladen**
      const localReviewsWithNewImages = await Promise.all(
        localReviews.map(async (review) => {
          if (review.customerImage.startsWith("blob:")) {
            console.log(
              "Blob-URL gefunden, konvertiere in Base64:",
              review.customerImage
            );
            const base64Image = await fetch(review.customerImage)
              .then((res) => res.blob())
              .then(
                (blob) =>
                  new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result);
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                  })
              );

            if (base64Image) {
              const uploadedImage =
                await UploadToCloudService.uploadBase64Image(
                  [base64Image],
                  "image"
                );

              if (
                Array.isArray(uploadedImage) &&
                uploadedImage.length > 0 &&
                uploadedImage[0].secure_url
              ) {
                console.log(
                  "✅ Bild erfolgreich hochgeladen:",
                  uploadedImage[0].secure_url
                );
                return {
                  ...review,
                  customerImage: uploadedImage[0].secure_url,
                };
              }
            }
            return {
              ...review,
              customerImage: "",
            };
          }
          return review;
        })
      );

      console.log("LocalReviews mit neuen Bildern:", localReviewsWithNewImages);
      setLocalReviews(localReviewsWithNewImages);

      // **Fragen-Bilder hochladen (Falls Blob-URL)**
      const questionDataWithNewImages = await Promise.all(
        questionData.map(async (question) => {
          if (question.backgroundImage.startsWith("blob:")) {
            console.log(
              "📌 Frage-Bild als Blob gefunden, wird hochgeladen:",
              question.backgroundImage
            );

            const base64Image = await fetch(question.backgroundImage)
              .then((res) => res.blob())
              .then(
                (blob) =>
                  new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result);
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                  })
              );

            if (base64Image) {
              const uploadedImage =
                await UploadToCloudService.uploadBase64Image(
                  [base64Image],
                  "image"
                );

              if (
                Array.isArray(uploadedImage) &&
                uploadedImage.length > 0 &&
                uploadedImage[0].secure_url
              ) {
                console.log(
                  "✅ Frage-Bild erfolgreich hochgeladen:",
                  uploadedImage[0].secure_url
                );
                return {
                  ...question,
                  backgroundImage: uploadedImage[0].secure_url,
                };
              }
            }
            return {
              ...question,
              backgroundImage: "",
            };
          }
          return question;
        })
      );

      console.log("✅ Fragen mit neuen Bildern:", questionDataWithNewImages);
      setQuestionData(questionDataWithNewImages);

      // **PictureSection Bilder hochladen (Falls Blob-URL)**
      const pictureSectionWithNewImages = await Promise.all(
        Object.keys(templateData.pictureSection).map(async (key) => {
          const section = templateData.pictureSection[key];
          if (section && section.img && section.img.startsWith("blob:")) {
            console.log(
              "Blob-URL in PictureSection gefunden für key",
              key,
              section.img
            );
            // Blob in Base64 konvertieren
            const base64Image = await fetch(section.img)
              .then((res) => res.blob())
              .then(
                (blob) =>
                  new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result);
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                  })
              );
            if (base64Image) {
              const uploadedImage =
                await UploadToCloudService.uploadBase64Image(
                  [base64Image],
                  "image"
                );
              if (
                Array.isArray(uploadedImage) &&
                uploadedImage.length > 0 &&
                uploadedImage[0].secure_url
              ) {
                console.log(
                  "✅ PictureSection Bild erfolgreich hochgeladen:",
                  uploadedImage[0].secure_url
                );
                return {
                  key,
                  newSection: {
                    ...section,
                    img: uploadedImage[0].secure_url,
                  },
                };
              }
            }
            return {
              key,
              newSection: {
                ...section,
                img: "",
              },
            };
          }
          // Falls kein Blob vorliegt, wird der Abschnitt unverändert übernommen
          return { key, newSection: section };
        })
      );

      // Neues pictureSection-Objekt aus den hochgeladenen Bildern erstellen
      const updatedPictureSection = pictureSectionWithNewImages.reduce(
        (acc, { key, newSection }) => {
          acc[key] = newSection;
          return acc;
        },
        {}
      );

      // Update im Context: pictureSection wird mit den neuen Bild-URLs überschrieben
      console.log("✅ Aktualisierte PictureSection:", updatedPictureSection);

      // **PromoSection Bild hochladen (Falls Blob-URL)**
      let newPromoSection = { ...templateData.promoSection };
      console.log(newPromoSection + "Hallo");

      if (
        newPromoSection &&
        newPromoSection.img &&
        newPromoSection.img.startsWith("blob:")
      ) {
        console.log("Blob-URL in promoSection gefunden:", newPromoSection.img);

        // Blob in Base64 konvertieren
        const base64Image = await fetch(newPromoSection.img)
          .then((res) => res.blob())
          .then(
            (blob) =>
              new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
              })
          );

        if (base64Image) {
          const uploadedImage = await UploadToCloudService.uploadBase64Image(
            [base64Image],
            "image"
          );

          if (
            Array.isArray(uploadedImage) &&
            uploadedImage.length > 0 &&
            uploadedImage[0].secure_url
          ) {
            console.log(
              "✅ PromoSection Bild erfolgreich hochgeladen:",
              uploadedImage[0].secure_url
            );
            newPromoSection.img = uploadedImage[0].secure_url;
          } else {
            newPromoSection.img = "";
          }
        } else {
          // Falls das Konvertieren schiefgeht
          newPromoSection.img = "";
        }
      }

      // newTemplateData enthält bereits die aktualisierte pictureSection mit den neuen Cloudinary-URLs
      const newTemplateData = {
        ...templateData,
        pictureSection: updatedPictureSection,
        promoSection: newPromoSection,
      };

      console.log("TemplateData", templateData);

      // Template-Payload aus allNewValues erstellen
      let templatePayload = allNewValues.reduce(
        (accumulator, currentObject) => ({
          ...accumulator,
          ...(currentObject?.templateData || {}),
        }),
        {}
      );

      // Hole den payload-spezifischen Part (möglicherweise wird hier der alte Zustand gemerged)
      templatePayload = getTemplateSpecificPayload(
        templatePayload,
        newTemplateData,
        landingPageData.theme
      );

      templatePayload = {
        ...templatePayload,
        promoSection: newTemplateData.promoSection,
        pictureSection: {
          activate: newTemplateData.pictureSection.activate,
          header: newTemplateData.pictureSection.header,
          one: {
            ...newTemplateData.pictureSection.one,
            header: newTemplateData.pictureSection.one.header,
            text: newTemplateData.pictureSection.one.text,
          },
          two: {
            ...newTemplateData.pictureSection.two,
            header: newTemplateData.pictureSection.two.header,
            text: newTemplateData.pictureSection.two.text,
          },
          three: {
            ...newTemplateData.pictureSection.three,
            header: newTemplateData.pictureSection.three.header,
            text: newTemplateData.pictureSection.three.text,
          },
          four: {
            ...newTemplateData.pictureSection.four,
            header: newTemplateData.pictureSection.four.header,
            text: newTemplateData.pictureSection.four.text,
          },
          five: {
            ...newTemplateData.pictureSection.five,
            header: newTemplateData.pictureSection.five.header,
            text: newTemplateData.pictureSection.five.text,
          },
        },
      };

      console.log("📌 Aktualisiertes Template-Payload:", templatePayload);

      // **Sende alle Daten an den Server**
      console.log("Sende Daten an den Server...");
      await Promise.all([
        LandingPageService.update(landingpageId, landingPagePayload),
        LandingPageService.updateTemplate(
          landingPageData.templateXyz._id,
          landingPageData.theme,
          templatePayload
        ),
        localReviewService.wipeAndAdd(landingpageId, localReviewsWithNewImages),
        QuestionService.wipeAndAdd(landingpageId, questionDataWithNewImages),
      ]);

      console.log("Daten erfolgreich gespeichert.");
      clearMessage("lp-config-loading");
      showMessage("success", "Erfolgreich gespeichert!");
    } catch (error) {
      console.error("Fehler beim Speichern:", error);
      showMessage(
        "error",
        error.response?.status === 429
          ? "Etwas ist schiefgelaufen. Rate Limit erreicht, bitte versuchen Sie es später erneut."
          : `Etwas ist schiefgelaufen: ${error.message}`
      );
      clearMessage("lp-config-loading");
    } finally {
      setIsSaving(false);
      store.dispatch(setLoading(false));
      console.log("saveAll() beendet.");
    }
  };

  useImperativeHandle(ref, () => ({
    saveAll,
    isSaving,
  }));

  return (
    <div className="">
      <div className="w-[95%] m-auto space-y-4">
        <Header
          className={""}
          onArrowClick={() => navigate("/dashboard/landingpage")}
          onClose={onClose}
          onButtonClick={() =>
            window.open(
              `/landingpage/${type}/${landingpageId}/viewmode`,
              "_blank"
            )
          }
        />
        {isLoading ? (
          <div className="m-auto w-min">
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />}
            />
          </div>
        ) : (
          <div className="flex flex-col gap-10">
            <HeroConfig
              landingPageData={landingPageData}
              ref={heroConfigRef}
              type={type}
            />
            <Divider style={{ borderColor: "black", borderWidth: "2px" }} />
            <TemplateXyzConfig
              templateData={templateData}
              ref={templateXyzRef}
            />
            <Divider style={{ borderColor: "black", borderWidth: "2px" }} />
            <IntroductionToClient
              landingPageData={landingPageData}
              ref={introductionToClient}
            />

            <Rechtliches
              landingPageData={landingPageData}
              ref={rechtlichesRef}
            />
            <Pramien landingPageData={landingPageData} ref={pramienRef} />
            <BegleitTextConfig
              landingPageData={landingPageData}
              ref={begleittextRef}
            />
            <NameConfig landingPageData={landingPageData} ref={nameConfigRef} />
            <Button
              text="Speichern"
              onClick={saveAll}
              className="hidden my-10 primary:block"
              disabled={isSaving || isLoading}
              loading={isSaving}
            />
          </div>
        )}
      </div>
    </div>
  );
});

export default LandingPageConfig;
