import { Dropdown, Menu, Spin } from "antd";
import React, { useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  BASICSKELETTON,
  PAGESKELETTON,
  PAGESKELETTON2,
  QUESTIONSKLETTON,
  STROMSKLETTON,
} from "../../assets/Images";
import newLP from "../../assets/Images/newVorlage.png";
import { selectUser } from "../../redux/auth/selectors";
import LandingPageService from "../../service/LandingPageService";
import { aboTarif } from "../../utils/divers";

const activeTemplates = {
  basic: "Kostenplaner",
  question: "Kostenplaner",
  finance: "Finanzberater",
  property: "Immobilien",
  stormgas: "Strom & Gas",
};

const updateTemplates = {
  xyz: "XYZ",
};

const designNames = {
  basic: "Basic",
  question: "Quick",
  finance: "Bullet",
  property: "Picture",
  stormgas: "Arrow",
  xyz: "XYZ",
};

const bgSeter = (bg) => {
  return {
    backgroundImage: `url(${bg})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };
};

const TemplateCard = ({
  template,
  isActive,
  user,
  navigate,
  index,
  isUnlocked = true,
}) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [loadingVorlage, setLoadingVorlage] = useState(false);
  const createLandingPage = async (option) => {
    setLoadingVorlage(true);
    // Angepasste Logik für Hintergrundbilder
    const backgroundImage = (() => {
      switch (template) {
        case "basic":
          return "/images/Basic.jpg";
        case "stormgas":
          return "/landingPageBgs/stormgas.png";
        case "finance":
          return "/landingPageBgs/finance.svg";
        case "question":
          return "/landingPageBgs/bgQuestion.svg";
        default:
          return `/landingPageBgs/${template}.png`;
      }
    })();

    try {
      const adjustedTheme = template === "question" ? "basic" : template;
      const adjustedOption = template === "question" ? "question" : option;
      const { data } = await LandingPageService.create({
        primary_image: user?.avatar,
        theme: adjustedTheme,
        user_id: user?._id,
        background_image: backgroundImage,
        vorlage: adjustedOption,
      });

      if (template !== "xyz") {
        navigate(`/landingpageconfig/${adjustedTheme}/${data._id}`);
      } else {
        navigate(`/newLPKonfig/${adjustedTheme}/${data._id}`);
      }
    } catch (error) {
      console.error("Error creating landing page:", error);
    }
    setLoadingVorlage(false);
  };

  const handleMenuClick = (option) => {
    setSelectedOption(option);
    if (template === "basic") {
      createLandingPage(option);
    }
  };

  const handleCardClick = () => {
    if (!isActive) return;
    if (!isUnlocked) return;

    if (template === "basic" && !selectedOption) {
      alert("Bitte Vorlage auswählen!");
      return;
    }

    createLandingPage(selectedOption);
  };

  const menu = (
    <Menu>
      <Menu.Item
        key="Kostenplaner"
        onClick={(e) => {
          e.domEvent.stopPropagation();
          handleMenuClick("Kostenplaner");
        }}
      >
        Kostenplaner
      </Menu.Item>
      <Menu.Item
        key="Strom & Gas"
        onClick={(e) => {
          e.domEvent.stopPropagation();
          handleMenuClick("Strom & Gas");
        }}
      >
        Strom & Gas
      </Menu.Item>
      <Menu.Item
        key="Photovoltaik"
        onClick={(e) => {
          e.domEvent.stopPropagation();
          handleMenuClick("pv");
        }}
      >
        Photovoltaik
      </Menu.Item>
      <Menu.Item
        key="Reise"
        onClick={(e) => {
          e.domEvent.stopPropagation();
          handleMenuClick("reise");
        }}
      >
        Reise
      </Menu.Item>
    </Menu>
  );

  const getTemplateImage = () => {
    switch (template) {
      case "finance":
        return PAGESKELETTON;
      case "basic":
        return BASICSKELETTON;
      case "stormgas":
        return STROMSKLETTON;
      case "question":
        return QUESTIONSKLETTON;
      default:
        return "";
    }
  };

  const getImageStyles = () => {
    const baseStyles = "absolute ";
    const commonStyles =
      "h-[4rem] top-[1rem] right-[1.5rem] sm:h-[6rem] sm:right-[5rem] sm:top-[3rem] md:h-[8rem] md:top-[1rem] md:right-[5rem] lg:h-[9rem] lg:top-[1rem] lg:right-[8rem]";
    const offsetStyles =
      "h-[4rem] top-[1rem] right-[2rem] sm:h-[6rem] sm:right-[6rem] sm:top-[3rem] md:h-[8rem] md:top-[1rem] md:right-[6rem] lg:h-[9rem] lg:top-[1rem] lg:right-[9.5rem]";

    switch (template) {
      case "finance":
      case "stormgas":
      case "property":
      case "xyz":
        return baseStyles + commonStyles;
      case "basic":
      case "question":
        return baseStyles + offsetStyles;
    }
  };

  const getBackground = () => {
    if (!isActive || !isUnlocked) {
      return bgSeter(
        `/images/card-bg-${template === "stormgas" ? "2" : "4"}.png`
      );
    }

    switch (template) {
      case "basic":
        return bgSeter("/images/Basic.jpg");
      case "stormgas":
        return bgSeter("/landingPageBgs/strom-bild-create.png");
      case "question":
        return bgSeter("/landingPageBgs/bgQuestion.svg");
      case "finance":
        return bgSeter("/landingPageBgs/bullet.webp");
      case "property":
        return bgSeter("/landingPageBgs/property.png");
      default:
        return bgSeter("/landingPageBgs/bgXyz.svg");
    }
  };

  return (
    <div
      className={`rounded-11xl relative ${
        isActive && isUnlocked
          ? "cursor-pointer transition-transform duration-300 hover:scale-[1.02] hover:shadow-xl"
          : "cursor-not-allowed opacity-50 pointer-events-none"
      }`}
      onClick={() => handleCardClick(selectedOption)}
    >
      <div
        style={getBackground()}
        className={`absolute inset-0 rounded-11xl ${
          ["property", "basic", "stormgas"].includes(template)
            ? "brightness-50 blur-[1px]"
            : ""
        } ${template === "basic" ? "w-full h-full" : ""}`}
      />

      {isActive && isUnlocked && (
        <>
          <img src={getTemplateImage()} className={getImageStyles()} />
          {template === "xyz" && (
            <div className="absolute -top-12 -left-11">
              <img src={newLP} className="w-32 h-32" />
            </div>
          )}
        </>
      )}

      {!isActive && (
        <div className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] text-xl sm:text-3xl whitespace-nowrap text-yellow-500 z-10 font-bold animate-pulse drop-shadow-[0_0_15px_rgba(234,179,8,0.5)]">
          WIRD GEUPDATET...
        </div>
      )}
      {!isUnlocked && (
        <div className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] text-xl sm:text-3xl whitespace-nowrap text-yellow-500 z-10 font-bold animate-pulse drop-shadow-[0_0_15px_rgba(234,179,8,0.5)]">
          UPGRADE NÖTIG
        </div>
      )}
      {loadingVorlage ? (
        <Spin
          className="custom-spin absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]"
          size="large"
        />
      ) : null}
      <div
        className={`h-full relative ${
          !isActive || !isUnlocked
            ? "before:absolute before:inset-0 before:bg-[#000000] before:bg-opacity-50 before:backdrop-brightness-50 before:rounded-11xl"
            : ""
        } sm:p-10 p-5`}
      >
        <h1
          className={`sm:text-primary text-responsiveHeading ${
            isActive &&
            isUnlocked &&
            (template === "property" ||
              template === "basic" ||
              template === "stormgas" ||
              template === "question")
              ? "text-white"
              : "text-[#000000]"
          } font-bold`}
        >
          {isActive
            ? "Design: " + designNames[template]
            : activeTemplates[template] || updateTemplates[template]}
        </h1>

        <p
          className={`sm:text-9xl text-xl ${
            isActive &&
            (template === "property" ||
              template === "basic" ||
              template === "stormgas" ||
              template === "question")
              ? "text-white"
              : "text-[#000000]"
          } font-semibold`}
        >
          {template === "basic" ? (
            <Dropdown
              overlay={menu}
              trigger={["click"]}
              overlayClassName="z-50"
            >
              <button
                className="bg-white p-2 rounded-md shadow-md text-sm z-40 text-[#000000]"
                onClick={(e) => e.stopPropagation()}
              >
                {selectedOption || "Vorlage auswählen"}
              </button>
            </Dropdown>
          ) : (
            "Vorlage " +
            (activeTemplates[template] || updateTemplates[template])
          )}
        </p>
        {template === "xyz" && (
          <p className="text-sm">
            Diese Landing Page nutzt den neuen Konfigurator
          </p>
        )}
      </div>
    </div>
  );
};

const Index = () => {
  const navigate = useNavigate();
  const { user } = useSelector(selectUser);

  return (
    <div className="max-w-[2000px] flex justify-center w-full m-auto">
      <div className="w-full">
        <div className="flex w-10/12 m-auto justify-between items-center my-10">
          <div className="xl:text-primary text-xl cursor-pointer text-[#868686]">
            <BsArrowLeft onClick={() => navigate(-1)} />
          </div>
          <div>
            <img
              src="/images/logo.png"
              alt=""
              className="sm:w-[50px] w-[30px]"
            />
          </div>
          <div></div>
        </div>
        <div className="text-9xl text-center font-semibold">
          <h1>Wähle hier zunächst eine für Dich passende Vorlage aus.</h1>
          <h1>Anschließend kannst Du im Konfigurator Anpassungen vornehmen.</h1>
        </div>
        <div className="sm:w-[80%] w-[95%] m-auto flex flex-col sm:gap-[50px] gap-3 my-10">
          {/* Active Templates */}
          {Object.keys(activeTemplates).map((template) => (
            <TemplateCard
              key={template}
              template={template}
              isActive={true}
              user={user}
              navigate={navigate}
              isUnlocked={aboTarif(user) >= 1 || template === "basic"}
            />
          ))}

          {/* Update Templates */}
          {Object.keys(updateTemplates).map((template) => (
            <TemplateCard
              key={template}
              template={template}
              isActive={false}
              user={user}
              navigate={navigate}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Index;
