import React, { useRef, useState } from "react";
import {
  handlePlay,
  handleEnded,
  handlePause,
  sendEventUser,
} from "../../../utils/analytics";
import { imageBackground } from "../../../utils/setImageBackground";
import Inputs from "../shared/Input";

const LandingPageClosingFormular = ({
  privacy,
  setPrivacy,
  positive,
  landingPageData,
  onWhatsappButton,
  onChoseDate,
  onRecall,
  number,
  name,
  setName,
  setNumber,
  visible,
  disableButtons,
  noQuestions,
}) => {
  const textColor = positive ? "text-[#40C945]" : "text-[#E81900]";

  // Google Analytics
  const [firstPlay, setFirstPlay] = useState(true);
  const [watchtime, setWatchtime] = useState(0);
  const videoRef = useRef();

  const googleAnalyticsHandler = (action, label) => {
    const handlers = {
      play: () =>
        handlePlay(
          videoRef,
          setWatchtime,
          firstPlay,
          setFirstPlay,
          landingPageData._id,
          "v2",
          true,
          false
        ),
      pause: () =>
        handlePause(
          videoRef,
          watchtime,
          setWatchtime,
          landingPageData._id,
          "v2",
          true,
          false
        ),
      end: () =>
        handleEnded(
          videoRef,
          watchtime,
          setWatchtime,
          landingPageData._id,
          "v2",
          true,
          false
        ),
      cta: () =>
        sendEventUser({
          category: "button",
          action: `click_${label}`,
          label: label,
          value: 1,
          landingPageId: landingPageData._id,
        }),
    };

    handlers[action]?.();
  };

  console.log(landingPageData);
  /**Text */
  let textFirst = positive
    ? landingPageData?.aufgrundDeinerAngaben
    : landingPageData?.negativeText;

  textFirst = noQuestions ? landingPageData?.aufgrundDeinerAngaben : textFirst;

  const textSecound = positive
    ? landingPageData?.wirLernenUnsAufDemText ??
      "Wir lernen uns auf dem von Dir gewünschten Weg kurz kennen, klären Fragen und finden heraus ob bzw. wie wir weiter machen."
    : landingPageData?.fallsDuDennochText ??
      "Falls Du dennoch der Meinung bist das Dir ein Gespräch mit mir weiterhelfen könnte und ich vielleicht nur die falschen Fragen gestellt habe, dann trage Dich trotzdem gerne ein.";

  return (
    visible && (
      <div
        className="flex items-center justify-center w-screen min-h-screen py-5 overflow-scroll"
        style={{
          ...imageBackground(landingPageData?.background_image),
        }}
      >
        <div className="w-[90%] xs:w-[80%] p-5 bg-white rounded-sm max-w-[1000px] overflow-scroll">
          <div className="max-w-[800px] m-auto flex flex-col gap-5">
            <div
              className={`md:text-9xl text-xl font-semibold text-center ${textColor}`}
            >
              <p>{textFirst}</p>
            </div>
            {!landingPageData?.activateClosingVideo || !positive ? (
              <div className="text-base font-semibold text-center md:text-xl">
                <p>{textSecound}</p>
              </div>
            ) : (
              <video
                ref={videoRef}
                onPlay={() => googleAnalyticsHandler("play")}
                onPause={() => googleAnalyticsHandler("pause")}
                onEnded={() => googleAnalyticsHandler("end")}
                playsInline
                poster={landingPageData?.closingVideoThumbnail}
                width="600"
                controls
                className={`aspect-video rounded-sm m-auto xs:w-[500px] primary:w-[650px] object-cover shadow-[0_8px_30px_rgb(0,0,0,0.12)]`}
              >
                <source src={landingPageData?.closingVideo} type="video/mp4" />
                <source src={landingPageData?.closingVideo} type="video/webm" />
                Your browser does not support the video tag.
              </video>
            )}

            <div className="flex flex-col gap-5 md:flex-row">
              <Inputs
                onChange={(e) => {
                  setName(e.target.value);
                }}
                value={name}
                label={"Dein Name"}
                emptyCheckWithText={false}
              />
              <Inputs
                onChange={(e) => {
                  setNumber(e.target.value);
                }}
                value={number}
                label={"Mobilnummer"}
                tel={true}
                emptyCheckWithText={false}
              />
            </div>

            <Datenschutz checkbox={privacy} setCheckbox={setPrivacy} />

            <div className="flex flex-col gap-2 md:flex-row">
              {landingPageData?.activateWhatsApp &&
                positive &&
                landingPageData?.activate_wa3 && (
                  <Button
                    disabled={disableButtons}
                    text="WhatsApp schreiben"
                    backgroundColor="#25D366"
                    onClick={() => {
                      onWhatsappButton();
                      googleAnalyticsHandler("cta", "wa3");
                    }}
                  />
                )}
              {landingPageData?.activateCalendly && positive && (
                <Button
                  disabled={disableButtons}
                  text="Termin aussuchen"
                  backgroundColor="#0084F7"
                  onClick={() => {
                    onChoseDate();
                    googleAnalyticsHandler("cta", "calendly");
                  }}
                />
              )}
              {landingPageData?.activate_recall && (
                <Button
                  disabled={disableButtons}
                  textColor="black"
                  text="Rückrufbitte"
                  backgroundColor="#FFD814"
                  onClick={() => {
                    onRecall();
                    googleAnalyticsHandler("cta", "recall");
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default LandingPageClosingFormular;

const Button = ({
  backgroundColor = "grey",
  text,
  textColor = "white",
  onClick,
  disabled,
}) => (
  <button
    onClick={() => !disabled && onClick()}
    style={{ background: backgroundColor, color: textColor }}
    className={`w-full p-3 font-semibold rounded-sm md:p-4 text-md md:text-lg ${
      disabled ? "opacity-50 cursor-wait" : "opacity-100"
    }`}
  >
    {text}
  </button>
);

const Datenschutz = ({ checkbox, setCheckbox }) => {
  return (
    <>
      <div className="font-semibold text-[14px]">
        Diese Daten werden nur von mir und zum Zwecke der Erstkontaktaufnahme
        genutzt und können auf Deinen Wunsch jederzeit gelöscht werden.
      </div>
      <div className="flex items-center gap-3">
        <input
          required={true}
          type="checkbox"
          checked={checkbox}
          onChange={(e) => setCheckbox(e.target.checked)}
          style={{ accentColor: "blue", scale: "1.1" }}
        />
        <p style={{ textAlign: "left" }} className="font-semibold text-[14px]">
          Ich stimme der Datenschutzerklärung von EinGuterTipp zu
        </p>
      </div>
    </>
  );
};
