import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from "react";
import Upload from "antd/es/upload/Upload";
import { PlusOutlined } from "@ant-design/icons";
import { Rate, Switch, Button, Divider, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useLandingPage } from "../../../../components/New_LP_Configuration/context/LandingPageContext";
import UploadToCloudService from "../../../../service/UploadToCloudService";
import FontSelector from "../../shared/FontSelector";

const MAX_ICONS = 5;

const TemplateXyzConfig = forwardRef(({ templateData }, ref) => {
  const { setTemplateData } = useLandingPage();

  // State für aktive Sektionen (ohne pictureSection)
  const [activeSections, setActiveSections] = useState(() => ({
    bannerSection: templateData?.bannerSection?.activate ?? true,
    cardSection: templateData?.cardSection?.activate ?? true,
    aboutSection: templateData?.aboutSection?.activate ?? true,
  }));

  // Funktion zum Umschalten der Sektionen
  const handleToggleSection = (section) => {
    setActiveSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));

    setTemplateData((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        activate: !prev[section].activate,
      },
    }));
  };

  const [icons, setIcons] = useState(
    templateData?.bannerSection?.logos || Array(MAX_ICONS).fill(null)
  );
  const [iconPreviews, setIconPreviews] = useState(Array(MAX_ICONS).fill(null));
  const [iconFiles, setIconFiles] = useState(Array(MAX_ICONS).fill(null));

  // Initialisiere Icon-Previews aus templateData
  useEffect(() => {
    if (templateData?.bannerSection?.logos) {
      setIconPreviews(
        templateData.bannerSection.logos.map((url) => url || null)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateData]);

  // Imperative Save-Methode – hier wird der tatsächliche Upload ausgeführt
  useImperativeHandle(ref, () => ({
    async save() {
      // Icons hochladen (sofern es lokale Dateien gibt)
      const uploadedIconUrls = await Promise.all(
        iconFiles.map(async (file, index) => {
          // Falls keine neue Datei vorhanden, verwende vorhandene URL
          if (!file) return icons[index];
          // Falls bereits ein secure_url-Feld existiert, ist schon hochgeladen
          if (file.secure_url) return file.secure_url;
          // Hochladen via Cloud-Service
          const res = await UploadToCloudService.upload(
            file.originFileObj || file,
            "image"
          );
          return res.secure_url;
        })
      );

      console.log("uploadIcons", uploadedIconUrls);

      // State aktualisieren
      setIcons(uploadedIconUrls);

      // TemplateData im Kontext aktualisieren
      setTemplateData((prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          bannerSection: {
            ...prev.bannerSection,
            logos: uploadedIconUrls.filter(Boolean),
          },
        };
      });

      // Rückgabeobjekt (falls du es woanders weiterverarbeiten möchtest)
      return {
        templateData: {
          darkMode: templateData.darkMode,
          templateColor: templateData.templateColor,
          bannerSection: {
            ...templateData.bannerSection,
            logos: uploadedIconUrls,
          },
          aboutSection: {
            ...templateData.aboutSection,
            activate: activeSections.aboutSection,
          },
          cardSection: {
            ...templateData.cardSection,
            activate: activeSections.cardSection,
          },
        },
      };
    },
  }));

  // Handle Icon-Upload – nur lokale Vorschau generieren und Datei speichern
  const handleIconUploadChange = (index, info) => {
    if (info.file.status === "done") {
      // Falls die Upload-Response eine URL liefert, nimm sie als Preview
      const newIconUrl =
        info.file.response?.url ||
        (typeof info.file.response === "string" ? info.file.response : null);

      if (newIconUrl) {
        const newIcons = [...icons];
        newIcons[index] = newIconUrl;
        setIcons(newIcons);

        const newPreviews = [...iconPreviews];
        newPreviews[index] = newIconUrl;
        setIconPreviews(newPreviews);

        const newIconFiles = [...iconFiles];
        newIconFiles[index] = info.file;
        setIconFiles(newIconFiles);

        // Update global context
        setTemplateData((prev) => {
          if (!prev) return prev;
          const updatedLogos = newIcons.filter(Boolean);
          return {
            ...prev,
            bannerSection: {
              ...prev.bannerSection,
              logos: updatedLogos,
            },
          };
        });
      }
    }
  };

  // Upload-Button-Rendering
  const renderUploadButton = () => (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  // Bild-/Icon-Vorschau
  const renderImagePreview = (imageUrl) => {
    if (typeof imageUrl !== "string") return renderUploadButton();
    return (
      <div style={{ position: "relative", width: "100%", height: "100%" }}>
        <img
          src={imageUrl}
          alt="Preview"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "2px",
          }}
        />
      </div>
    );
  };

  // Dummy-Request-Funktion, um lokale Uploads zu simulieren
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      const blobUrl = URL.createObjectURL(file);
      onSuccess({ url: blobUrl }, file);
    }, 100);
  };

  return (
    <div className="flex flex-col gap-12 font-semibold text-2xl text-center">
      <div className="w-full">
        <FontSelector />
      </div>
      <Divider style={{ borderColor: "black", borderWidth: "2px" }} />
      <div className="w-full">Aktiviere/Deaktiviere deine Sektionen</div>
      <div className="flex flex-col gap-3 font-semibold text-2xl text-center">
        {/* Banner-Sektion */}
        <div className="flex items-center justify-between text-lg">
          <span>Banner-Sektion</span>
          <Switch
            checked={activeSections.bannerSection}
            onChange={() => handleToggleSection("bannerSection")}
          />
        </div>
      </div>

      {/* Icons Upload */}
      <div className="w-full text-center">
        <div className="inline-flex items-center justify-center gap-2">
          <span>Lade deine Icons für den Logo-Banner hoch (max. 5)</span>
          <Tooltip title="Bitte schneide die Bilder vor dem Upload möglichst so zu, dass sie keinen Rand haben.">
            <InfoCircleOutlined className="text-gray-500 cursor-pointer" />
          </Tooltip>
        </div>
      </div>
      <div className="flex flex-row flex-wrap gap-2">
        {Array.from({ length: MAX_ICONS }).map((_, index) => (
          <Upload
            accept="image/*"
            key={`icon_${index}`}
            name={`icon_${index}`}
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            customRequest={dummyRequest}
            onChange={(info) => handleIconUploadChange(index, info)}
          >
            {iconPreviews[index]
              ? renderImagePreview(iconPreviews[index])
              : renderUploadButton()}
          </Upload>
        ))}
      </div>
    </div>
  );
});

export default TemplateXyzConfig;
